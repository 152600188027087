import Vue from "vue";
import VueRouter from "vue-router";
import VueDaumPostcode from "vue-daum-postcode";
import VModal from "vue-js-modal";
import VResize from "vue-element-resize-detector";
import EvaIcons from "vue-eva-icons";
import VueSelect from "@desislavsd/vue-select";
import VueToast from "vue-toast-notification";
import VueViewer from "v-viewer";
import VueMobileDetection from 'vue-mobile-detection';


import router from "./router";
import store from "./store";
import App from "./App.vue";
import "./index.css";
import "@desislavsd/vue-select/dist/vue-select.css";
import "vue-toast-notification/dist/theme-sugar.css";
import "viewerjs/dist/viewer.css";

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(VueDaumPostcode);
Vue.use(VModal);
Vue.use(VResize);
Vue.use(EvaIcons);
Vue.use(VueSelect);
Vue.use(VueToast);
Vue.use(VueViewer);
Vue.use(VueMobileDetection);

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");
