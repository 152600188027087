import api from "./api";

export async function chatRoomCreate(payload) {
  try {
    const { status, data } = await api.post("chat_room", payload);

    if (status === "success") {
      return { data, success: true };
    }
  } catch (e) {
    console.log(e);
  }

  return { success: false, data: [] };
}

export async function chatRoomList(params) {
  try {
    const { status, data } = await api.get("chat_room", { params });

    if (status === "success") {
      return data;
    }
  } catch (e) {
    console.log(e);
  }

  return [];
}

export async function chatRoomJoinerList(params) {
  try {
    const { status, data } = await api.get("chat_joiner", { params });

    if (status === "success") {
      return data;
    }
  } catch (e) {
    console.log(e);
  }

  return [];
}

export async function chatMessageList(params) {
  try {
    const { status, data } = await api.get("chat_message", { params });

    if (status === "success") {
      return data;
    }
  } catch (e) {
    console.log(e);
  }

  return [];
}

export async function chatMessageCreate(payload) {
  const messageData = new FormData();
  for (const [key, value] of Object.entries(payload)) {
    messageData.append(key, value);
  }

  try {
    const { status, data } = await api.post("chat_message", messageData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    if (status === "success") {
      return data;
    }
  } catch (e) {
    console.log(e);
  }

  return null;
}
