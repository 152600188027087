<template>
  <header class="w-full flex justify-center items-center">
    <div
      class="header-inner container flex flex-row justify-between items-center"
    >
      <router-link 
        :to="{ name: 'Home' }"
        @click.native="hiddenMobileHeader"
        >
        <div class="logo flex flex-row items-center">
          <img src="@/assets/logo.png" alt="logo" />
          <h5 class="invisible md:visible">유휴공간 중개플랫폼</h5>
        </div>
      </router-link>

      <div class="nav-menu-icon">
        <img
          src="@/assets/list-icon.png"
          alt=""
          @click="toggleMobileOpenState"
        />
      </div>

      <div 
        class="menu-wrap flex flex-row items-center"
        :class="{ 'mobile-active': isMobileOpen  }"
      >
        <div class="menu">
          <router-link 
            :to="{ name: 'PlaceCreate' }"
            @click.native="hiddenMobileHeader"
            >노는공간 등록하기</router-link
          >
          <router-link 
            :to="{ name: 'PlaceList' }"
            @click.native="hiddenMobileHeader"
            >노는공간 찾기</router-link
          >
          <router-link 
            :to="{ name: 'InteriorOfferCreate' }"
            @click.native="hiddenMobileHeader"
            >인테리어 비교견적</router-link
          >
          <router-link 
            :to="{ name: 'InteriorReviewList' }"
            @click.native="hiddenMobileHeader"
            >시공 후기</router-link
          >
          <router-link 
            :to="{ name: 'NoticeList' }"
            @click.native="hiddenMobileHeader"
            >공지사항</router-link
          >
        </div>

        <div v-if="isLoggedIn" class="header-box flex flex-row items-center">
          <router-link 
            :to="{ name: 'MyPage' }"
            @click.native="hiddenMobileHeader"
            >마이페이지</router-link
          >
          <div class="space">|</div>
          <div @click="logoutEvent">로그아웃</div>
        </div>
        <div v-else class="header-box flex flex-row items-center">
          <router-link 
            :to="{ name: 'Signup' }"
            @click.native="hiddenMobileHeader"
            >회원가입</router-link
          >
          <div class="space">|</div>
          <router-link 
            :to="{ name: 'Login' }"
            @click.native="hiddenMobileHeader"
            >로그인</router-link
          >
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Header",
  data() {
    return {
      isMobileOpen: false,
    };
  },
  computed: {
    ...mapState("user", ["isLoggedIn", "userType"]),
  },
  methods: {
    toggleMobileOpenState: function() {
      this.isMobileOpen = !this.isMobileOpen;
    },
    hiddenMobileHeader: function() {
      this.isMobileOpen = false;
    },
    logoutEvent: function() {
      this.hiddenMobileHeader();
      this.logout();
    },
    ...mapActions("user", ["logout"]),
  },
};
</script>

<style scoped lang="scss">
header {
  height: 100px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.2);
  position: fixed;
  z-index: 200;
  background-color: white;
  .nav-menu-icon {
    display: none;
    &>img {
      width: 100%;
      object-fit: contain;
    }
  }
}

.header-inner {
  height: 100%;
}

.logo {
  cursor: pointer;

  img {
    width: 58px;
    height: 56px;
  }

  h5 {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -0.5px;
    color: #283aef;
    margin-left: 5px;
  }
}

.menu {
  a {
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.5px;
    color: #333333;
    margin-right: 60px;
  }
}

.header-box {
  height: 36px;
  border: 1px solid #707070;
  padding: 0 17px;

  a,
  div {
    font-size: 13px;
    font-weight: 400;
    color: #333333;
    letter-spacing: -0.5px;
    cursor: pointer;
    padding-top: 2px;
  }

  .space {
    font-size: 13px;
    font-weight: 400;
    color: #333333;
    letter-spacing: -0.5px;
    margin: 0 10px;
  }
}

@media (max-width: 1279px) {
  .menu {
    a {
      margin-right: 30px;
    }
  }
}
@media (max-width: 1023px) {
  header {
    height: auto;
    width: 100%;
    .header-inner {
      justify-content: center;
      .nav-menu-icon {
        position: absolute;
        left: calc(50vw + 310px);
        top: 0;
        display: flex;
        width: 30px;
        height: 100%;
        &>img {
          cursor: pointer;
          margin: auto 0;
        }
      }
      .menu-wrap {
        position: absolute;
        top: 100%;
        left: 0;
        transition: all .3s ease-in-out;
        background-color: white;
        padding: 0;
        width: 100%;
        justify-content: center;
        height: 0;
        overflow: hidden;
        &.mobile-active {
          height: 70px;
          padding: 10px;
        }
        .menu {
          z-index: 1;
        }
        .header-box {
          z-index: 1;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  header {
    .header-inner {
      .logo {
        h5 {
          display: none;
        }
      }
      .nav-menu-icon {
        left: unset;
        right: 20px;
      }
      .menu-wrap {
        display: flex;
        flex-direction: column;
        left: unset;
        right: 0;
        overflow-y: auto;
        overflow-x: hidden;
        width: auto;
        justify-content: flex-start;
        gap: 20px;
        align-items: flex-end;
        width: 0;
        height: calc(100vh - 56px);
        &.mobile-active {
          height: calc(100vh - 56px);
          width: 200px;
          padding: 10px 20px;
        }
        .menu {
          display: flex;
          flex-direction: column;
          gap: 10px;
          &>a {
            margin-right: 0;
            margin-left: auto;
            position: relative;
            white-space: nowrap;
            &:after {
              content: '';
              position: absolute;
              top: 100%;
              right: 0;
              width: 0;
              height: 1px;
              background-color: #707070;
              transition: all .3s ease-in-out;
            }
            &:hover {
              &:after {
                width: 100%;
              }
            }
          }
        }
        .header-box {
          display: flex;
          flex-direction: column;
          height: unset;
          border: none;
          gap: 10px;
          padding: 0;
          align-items: flex-end;          
          .space {
            display: none;
          }
          &>* {
            border: 1px solid #707070;
            padding: 5px 10px;
            cursor: pointer;
            transition: all .3s ease-in-out;
            text-align: center;
            min-width: 80px;
            &:hover {
              background-color: #707070;
              color: white;
            }
          }
        }
      }
    }
  }
}
</style>
