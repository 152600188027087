import api from "./api";

export async function authSignup(payload) {
  try {
    const { status, data } = await api.post("/auth/register", payload);

    if (status === "success") {
      const { user } = data;
      return { user, success: true };
    }
  } catch (e) {
    console.log(e);
  }

  return { user: null, success: false };
}

export async function authLogin(payload) {
  try {
    const { status, data } = await api.post("/auth/login", payload);
    if (status === "success") {
      const { user, accessToken: token } = data;
      return { user, token, success: true };
    }
  } catch (e) {
    console.log(e);
  }

  return { user: null, token: null, success: false };
}

export async function authSocial(payload) {
  try {
    const { status, data } = await api.post("/auth/sns/login/social", payload);
    if (status === "success") {
      const { user, accessToken: token } = data;
      return { user, token, success: true };
    }
  } catch (e) {
    console.log(e);
  }
}

export async function authSocialNaver(payload) {
  try {
    const { status, data } = await api.post(
      "/auth/sns/login/social/naver",
      payload
    );
    if (status === "success") {
      const { user, accessToken: token } = data;
      return { user, token, success: true };
    }
  } catch (e) {
    console.log(e);
  }
}

export async function authSocialKakao(payload) {
  try {
    const { status, data } = await api.post(
      "/auth/sns/login/social/kakao",
      payload
    );
    if (status === "success") {
      const { user, accessToken: token } = data;
      return { user, token, success: true };
    }
  } catch (e) {
    console.log(e);
  }
}

export async function authLogout() {
  try {
    await api.post("/auth/logout");
  } catch (e) {
    console.log(e);
  }
}

export async function verify(payload) {
  const result = await api.post("/verify", payload);
  return result;
}

export async function verifyCheck(payload) {
  try {
    const { status } = await api.post("/verify/check", payload);
    if (status === "success") {
      return true;
    }
  } catch (e) {
    console.log(e);
  }

  return false;
}
