import services from "@/services";

const LOCATION = "LOCATION";
const FORM = "FORM";
const LEASE = "LEASE";
const CLASSIFICATIONS = "CLASSIFICATION";
const RECOMMEND = "RECOMMEND";

const RECENT = "RECENT";
const PERMISSION = "PERMISSION";
const RESET = "RESET";

export const place = {
  namespaced: true,
  state: () => ({
    location: localStorage.getItem("location")
      ? JSON.parse(localStorage.getItem("location"))
      : {
          latitude: null,
          longitude: null,
          name: "",
        },
    leases: localStorage.getItem("leases")
      ? JSON.parse(localStorage.getItem("leases"))
      : [],
    classifications: localStorage.getItem("classifications")
      ? JSON.parse(localStorage.getItem("classifications"))
      : [],
    forms: localStorage.getItem("forms")
      ? JSON.parse(localStorage.getItem("forms"))
      : [],
    recents: localStorage.getItem("recentPlace")
      ? JSON.parse(localStorage.getItem("recentPlace"))
      : [],
    recommends: [],
    permission: false,
  }),
  mutations: {
    [LOCATION](state, payload) {
      localStorage.setItem("location", JSON.stringify(payload));
      state.location = payload;
    },
    [LEASE](state, payload) {
      localStorage.setItem("leases", JSON.stringify(payload));
      state.leases = payload;
    },
    [CLASSIFICATIONS](state, payload) {
      localStorage.setItem("classifications", JSON.stringify(payload));
      state.classifications = payload;
    },
    [FORM](state, payload) {
      localStorage.setItem("forms", JSON.stringify(payload));
      state.forms = payload;
    },
    [RECOMMEND](state, payload) {
      state.recommends = payload;
    },
    [RECENT](state, payload) {
      localStorage.setItem("recentPlace", JSON.stringify(payload));
      state.recents = payload;
    },
    [PERMISSION](state) {
      state.permission = true;
    },
    [RESET](state) {
      localStorage.setItem("location", "");
      state.location = {
        latitude: null,
        longitude: null,
        name: "",
      };
    },
  },
  actions: {
    setRecents({ commit }, payload) {
      commit(RECENT, payload);
    },
    async setLocation({ commit }, payload) {
      commit(LOCATION, payload);
    },
    async preparePlace({ commit }) {
      const [leases, classifications, forms] = await Promise.all([
        services.placeLeaseList(),
        services.placeClassificationList(),
        services.placeFormList(),
      ]);
      commit(LEASE, leases);
      commit(CLASSIFICATIONS, classifications);
      commit(FORM, forms);
    },
    async getRecommend({ commit }, payload) {
      const { data } = await services.placeList({ ...payload, paginate: 8 });
      commit(RECOMMEND, data);
    },
    setPermission({ commit }) {
      commit(PERMISSION);
    },
    resetLocation({ commit }) {
      commit(RESET);
    },
  },
  getters: {},
};
