import { default as api } from "./api";
import {
  authSignup,
  authLogin,
  authSocial,
  authSocialNaver,
  authSocialKakao,
  authLogout,
  verify,
  verifyCheck,
} from "./auth";
import {
  userDetail,
  termCategory,
  terms,
  getMarketing,
  userUpdate,
  userList,
} from "./user";
import {
  noticeList,
  noticeDetail,
  qnaList,
  qnaDetail,
  qnaCategory,
  qnaCreate,
  qnaUpdate,
  faqList,
} from "./cs";
import { reviewList, reviewCreate, reviewRemove } from "./review";
import {
  placeList,
  placeCreate,
  placeFormList,
  placeClassificationList,
  placeLeaseList,
  placeDetail,
  placeJoinOfferList,
  placeJoinOfferCreate,
  placeAddSubscribe,
  placeRemoveSubscribe,
  placeRemove,
  placeJoinOfferDetail,
  placeJoinOfferRemove,
  placeJoinOfferUpdate,
} from "./place";
import { lessorCreate } from "./lessor";
import {
  interiorOfficeCreate,
  interiorOfficeDetail,
  interiorOfficeList,
  interiorAddSubscribe,
  interiorRemoveSubscribe,
  interiorJoinOfferList,
  interiorOfferList,
  interiorOfferDetail,
  interiorJoinOfferCreate,
  interiorTimeList,
  interiorStyleList,
  interiorOfferCreate,
  portfolioList,
  portfolioCreate,
  portfolioDetail,
  interiorReviewDetail,
  interiorJoinOfferDetail,
  interiorJoinOfferRemove,
  interiorJoinOfferUpdate,
} from "./interior";
import {
  chatRoomCreate,
  chatRoomList,
  chatRoomJoinerList,
  chatMessageList,
  chatMessageCreate,
} from "./chat";

export default {
  api,
  authSignup,
  authLogin,
  authSocial,
  authSocialNaver,
  authSocialKakao,
  authLogout,
  verify,
  verifyCheck,
  userDetail,
  termCategory,
  terms,
  noticeList,
  noticeDetail,
  qnaList,
  qnaDetail,
  qnaCategory,
  qnaCreate,
  qnaUpdate,
  faqList,
  reviewList,
  placeList,
  placeCreate,
  placeFormList,
  placeClassificationList,
  placeLeaseList,
  placeDetail,
  placeJoinOfferList,
  placeJoinOfferCreate,
  placeAddSubscribe,
  placeRemoveSubscribe,
  lessorCreate,
  interiorOfficeCreate,
  interiorOfficeDetail,
  interiorOfficeList,
  interiorAddSubscribe,
  interiorRemoveSubscribe,
  interiorJoinOfferList,
  interiorOfferList,
  interiorOfferDetail,
  interiorJoinOfferCreate,
  interiorTimeList,
  interiorStyleList,
  interiorOfferCreate,
  portfolioList,
  portfolioCreate,
  portfolioDetail,
  chatRoomCreate,
  chatRoomList,
  chatRoomJoinerList,
  chatMessageList,
  chatMessageCreate,
  interiorReviewDetail,
  placeRemove,
  interiorJoinOfferDetail,
  interiorJoinOfferRemove,
  placeJoinOfferDetail,
  placeJoinOfferRemove,
  reviewCreate,
  reviewRemove,
  getMarketing,
  userUpdate,
  userList,
  interiorJoinOfferUpdate,
  placeJoinOfferUpdate,
};
