import Vue from "vue";
import Vuex from "vuex";

import { user } from "@/store/modules/user";
import { review } from "@/store/modules/review";
import { place } from "@/store/modules/place";
import { interior } from "@/store/modules/interior";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { user, review, place, interior },
});
