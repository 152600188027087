import api from "./api";

export async function interiorOfficeCreate(payload) {
  const interiorData = new FormData();
  for (const [key, value] of Object.entries(payload)) {
    if (Array.isArray(value)) {
      value.forEach((v) => {
        interiorData.append(key, v);
      });
    } else {
      interiorData.append(key, value);
    }
  }

  try {
    const { status, data } = await api.post("/interior_office", interiorData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    if (status === "success") {
      console.log("C", data);
      return [];
    }
  } catch (e) {
    console.log("D", e);
  }

  return [];
}

export async function interiorOfficeDetail(id) {
  try {
    const { status, data } = await api.get(`/interior_office/${id}`);

    if (status === "success") {
      return data;
    }
  } catch (e) {
    console.log("interiorOfficeDetail", e);
  }

  return {};
}

export async function interiorOfficeList(params) {
  try {
    const { status, data } = await api.get("/interior_office", { params });
    if (status === "success") {
      return data;
    }
  } catch (e) {
    console.log("interiorOfficeList", e);
  }

  return { currentPage: 1, data: [], total: 0, lastPage: 1 };
}

export async function interiorAddSubscribe(userId, officeId) {
  try {
    const { status } = await api.post("/user_subscribe_interior_office", {
      userId,
      officeId,
    });

    if (status === "success") {
      return true;
    }
  } catch (e) {
    console.log("interiorAddSubscribe", e);
  }
  return false;
}

export async function interiorRemoveSubscribe(placeId) {
  try {
    await api.delete(`/user_subscribe_interior_office/my/${placeId}`);
    return true;
  } catch (e) {
    console.log("interiorRemoveSubscribe", e);
  }
  return false;
}

export async function interiorJoinOfferList(params) {
  try {
    const { status, data } = await api.get("/interior_join_offer", { params });

    if (status === "success") {
      return data;
    }
  } catch (e) {
    console.log("interiorJoinOfferList", e);
  }

  return { currentPage: 1, data: [], total: 0, lastPage: 1 };
}

export async function interiorJoinOfferDetail(id) {
  try {
    const { status, data } = await api.get(`/interior_join_offer/${id}`);

    if (status === "success") {
      return data;
    }
  } catch (e) {
    console.log("interiorJoinOfferDetail", e);
  }

  return {};
}

export async function interiorJoinOfferRemove(offerId) {
  try {
    await api.delete(`/interior_join_offer/${offerId}`);
    return true;
  } catch (e) {
    console.log("interiorJoinOfferRemove", e);
  }
  return false;
}

export async function interiorOfferCreate(payload) {
  const interiorData = new FormData();
  for (const [key, value] of Object.entries(payload)) {
    if (Array.isArray(value)) {
      value.forEach((v) => {
        interiorData.append(key, v);
      });
    } else {
      interiorData.append(key, value);
    }
  }

  try {
    const { status } = await api.post("/interior_offer", interiorData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    if (status === "success") {
      return true;
    }
  } catch (e) {
    console.log("interiorOfferCreate: ", e);
  }

  return false;
}

export async function interiorOfferList(params) {
  try {
    const { status, data } = await api.get("/interior_offer", { params });

    if (status === "success") {
      return data;
    }
  } catch (e) {
    console.log("interiorOfferList", e);
  }

  return { currentPage: 1, data: [], total: 0, lastPage: 1 };
}

export async function interiorOfferDetail(id) {
  try {
    const { status, data } = await api.get(`/interior_offer/${id}`);

    if (status === "success") {
      return data;
    }
  } catch (e) {
    console.log("interiorOfferDetail", e);
  }

  return {};
}

export async function interiorJoinOfferCreate(payload) {
  try {
    const { status } = await api.post("/interior_join_offer", payload);

    if (status === "success") {
      return true;
    }
  } catch (e) {
    console.log("interiorJoinOfferCreate", e);
  }

  return false;
}

export async function interiorJoinOfferUpdate(id, payload) {
  try {
    const { status } = await api.post(`/interior_join_offer/${id}`, payload);

    if (status === "success") {
      return true;
    }
  } catch (e) {
    console.log("interiorJoinOfferCreate", e);
  }

  return false;
}

export async function interiorTimeList() {
  try {
    const { status, data } = await api.get("/interior_time");

    if (status === "success") {
      return data;
    }
  } catch (e) {
    console.log("interiorTimeList", e);
  }

  return [];
}

export async function interiorStyleList() {
  try {
    const { status, data } = await api.get("/interior_style");

    if (status === "success") {
      return data;
    }
  } catch (e) {
    console.log("interiorStyleList", e);
  }

  return [];
}

export async function portfolioList(params) {
  try {
    const { status, data } = await api.get("/portfolio", { params });

    if (status === "success") {
      return data;
    }
  } catch (e) {
    console.log("portfolioList", e);
  }

  return { currentPage: 1, data: [], total: 0, lastPage: 1 };
}

export async function portfolioDetail(id) {
  try {
    const { status, data } = await api.get(`/portfolio/${id}`);

    if (status === "success") {
      return data;
    }
  } catch (e) {
    console.log("portfolioList", e);
  }

  return {};
}

export async function portfolioCreate(payload) {
  const portfolioData = new FormData();
  for (const [key, value] of Object.entries(payload)) {
    if (Array.isArray(value)) {
      value.forEach((v) => {
        portfolioData.append(key, v);
      });
    } else {
      portfolioData.append(key, value);
    }
  }

  try {
    const { status } = await api.post("/portfolio", portfolioData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    if (status === "success") {
      return true;
    }
  } catch (e) {
    console.log("portfolioCreate: ", e);
  }

  return false;
}

export async function interiorReviewDetail(id) {
  try {
    const { status, data } = await api.get(`/trade_review/${id}`);

    if (status === "success") {
      return data;
    }
  } catch (e) {
    console.log("interiorReviewDetail", e);
  }

  return {};
}
