import VueRouter from "vue-router";
import store from "@/store";
import Vue from "vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/pages/Home"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/pages/Login"),
    meta: { notRequiresAuth: true },
  },
  {
    path: "/kakao",
    name: "Kakao",
    component: () => import("@/pages/Kakao"),
  },
  {
    path: "/naver",
    name: "Naver",
    component: () => import("@/pages/Naver"),
  },
  {
    path: "/signup",
    name: "Signup",
    component: () => import("@/pages/Signup"),
    meta: { notRequiresAuth: true },
  },
  {
    path: "/term",
    name: "Term",
    component: () => import("@/pages/Term"),
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () => import("@/pages/Privacy"),
  },
  {
    path: "/mypage",
    name: "MyPage",
    component: () => import("@/pages/MyPage"),
    meta: { requiresAuth: true },
  },
  {
    path: "/mypage/review",
    name: "MyPageReview",
    component: () => import("@/pages/MyReviewList"),
    meta: { requiresAuth: true },
  },
  {
    path: "/history",
    name: "History",
    component: () => import("@/pages/History"),
    meta: { requiresAuth: true },
  },
  {
    path: "/favorite",
    name: "Favorite",
    component: () => import("@/pages/Favorite"),
    meta: { requiresAuth: true, userType: "normal" },
  },
  {
    path: "/notice",
    name: "NoticeList",
    component: () => import("@/pages/NoticeList"),
  },
  {
    path: "/notice/:id",
    name: "NoticeDetail",
    component: () => import("@/pages/NoticeDetail"),
  },
  {
    path: "/qna",
    name: "QnaList",
    component: () => import("@/pages/QnaList"),
  },
  {
    path: "/qna/write",
    name: "QnaWrite",
    component: () => import("@/pages/QnaWrite"),
    meta: { requiresAuth: true },
  },
  {
    path: "/qna/write/:id",
    name: "QnaUpdate",
    component: () => import("@/pages/QnaWrite"),
    meta: { requiresAuth: true },
  },
  {
    path: "/qna/:id",
    name: "QnaDetail",
    component: () => import("@/pages/QnaDetail"),
    meta: { requiresAuth: true },
  },
  {
    path: "/faq",
    name: "FaqList",
    component: () => import("@/pages/FaqList"),
  },
  {
    path: "/place",
    name: "PlaceList",
    component: () => import("@/pages/PlaceList"),
  },
  {
    path: "/place/create",
    name: "PlaceCreate",
    component: () => import("@/pages/PlaceWrite"),
  },
  {
    path: "/place/offer/join/:id",
    name: "PlaceOfferJoinList",
    component: () => import("@/pages/PlaceOfferJoinList"),
    meta: { requiresAuth: true },
  },
  {
    path: "/place/offer/:id",
    name: "PlaceOffer",
    component: () => import("@/pages/PlaceOffer"),
    meta: { requiresAuth: true },
  },
  {
    path: "/place/:id",
    name: "PlaceDetail",
    component: () => import("@/pages/PlaceDetail"),
  },
  // {
  //   path: "/place/:id/review",
  //   name: "PlaceReviewCreate",
  //   component: () => import("@/pages/PlaceReviewWrite"),
  //   meta: { requiresAuth: true },
  // },
  {
    path: "/interior",
    name: "InteriorList",
    component: () => import("@/pages/InteriorList"),
  },
  {
    path: "/interior/review",
    name: "InteriorReviewList",
    component: () => import("@/pages/InteriorReviewList"),
  },
  {
    path: "/interior/review/:id",
    name: "InteriorReviewDetail",
    component: () => import("@/pages/InteriorReviewDetail"),
  },
  {
    path: "/interior/offer/create",
    name: "InteriorOfferCreate",
    component: () => import("@/pages/InteriorOfferCreate"),
    meta: { requiresAuth: true },
  },
  {
    path: "/interior/offer/join/:id",
    name: "InteriorOfferJoinList",
    component: () => import("@/pages/InteriorOfferJoinList"),
    meta: { requiresAuth: true },
  },
  {
    path: "/interior/offer/:id",
    name: "InteriorOffer",
    component: () => import("@/pages/InteriorOffer"),
    meta: { requiresAuth: true },
  },
  {
    path: "/interior/offer/:id/review",
    name: "InteriorReviewCreate",
    component: () => import("@/pages/InteriorReviewWrite"),
    meta: { requiresAuth: true },
  },
  {
    path: "/interior/office/:id",
    name: "InteriorOfficeDetail",
    component: () => import("@/pages/InteriorOfficeDetail"),
  },
  {
    path: "/find/password",
    name: "FindPassword",
    component: () => import("@/pages/FindPassword"),
  },
  {
    path: "/me/update",
    name: "UserUpdate",
    component: () => import("@/pages/UserUpdate"),
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = store.state.user.isLoggedIn;
  const userType = store.state.user.userType;
  window.scrollTo(0, 0);

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isLoggedIn) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else if (
      to.matched.some(
        (record) => record.meta.userType && record.meta.userType !== userType
      )
    ) {
      new Vue().$toast.open({
        message: "해당 페이지에 접근권한이 없습니다",
        type: "error",
      });
      next(from);
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.notRequiresAuth)) {
    if (isLoggedIn) {
      next({
        path: "/",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
