import services from "@/services";

const TIME = "TIME";
const STYLE = "STYLE";
const ADD_INTERIORS = "ADD_INTERIORS";

const RECENT = "RECENT";

export const interior = {
  namespaced: true,
  state: () => ({
    times: localStorage.getItem("times")
      ? JSON.parse(localStorage.getItem("times"))
      : [],
    styles: localStorage.getItem("styles")
      ? JSON.parse(localStorage.getItem("styles"))
      : [],
    interiors: [],
    recents: localStorage.getItem("recentInterior")
      ? JSON.parse(localStorage.getItem("recentInterior"))
      : [],
  }),
  mutations: {
    [TIME](state, payload) {
      localStorage.setItem("times", JSON.stringify(payload));
      state.times = payload;
    },
    [STYLE](state, payload) {
      localStorage.setItem("styles", JSON.stringify(payload));
      state.styles = payload;
    },
    [ADD_INTERIORS](state, payload) {
      state.interiors = payload;
    },
    [RECENT](state, payload) {
      localStorage.setItem("recentInterior", JSON.stringify(payload));
      state.recents = payload;
    },
  },
  actions: {
    setRecents({ commit }, payload) {
      commit(RECENT, payload);
    },
    async prepareInterior({ commit }) {
      const [times, styles] = await Promise.all([
        services.interiorTimeList(),
        services.interiorStyleList(),
      ]);
      commit(TIME, times);
      commit(STYLE, styles);
    },
    async getInteriorOfficeList({ commit }) {
      const { data } = await services.interiorOfficeList({
        paginate: 8,
        page: 1,
      });
      commit(ADD_INTERIORS, data);
    },
  },
  getters: {},
};
