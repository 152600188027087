import api from "./api";

/**
 * 공간 목록 API
 * @param {leaseId} 임대 구분 ID
 * @param {ownerId} 임대인 ID
 * @param {classificationId} 임대인 구분 ID
 * @param {title} 공간명 검색
 * @param {size} 평수 검색
 * @param {subscribeUserId} 즐겨찾기 유저 ID
 * @param {paginate} 한 페이지당 아이템 수
 * @returns
 */
export async function placeList(params) {
  try {
    const { status, data } = await api.get("/place", { params });

    if (status === "success") {
      return data;
    }
  } catch (e) {
    console.log("placeList", e);
  }

  return { currentPage: 1, data: [], total: 0, lastPage: 1 };
}

export async function placeDetail(id) {
  try {
    const { status, data } = await api.get(`/place/${id}`);

    if (status === "success") {
      return data;
    }
  } catch (e) {
    console.log("placeList", e);
  }

  return {};
}

export async function placeCreate(payload) {
  const placeData = new FormData();
  for (const [key, value] of Object.entries(payload)) {
    if (Array.isArray(value)) {
      value.forEach((v) => {
        placeData.append(key, v);
      });
    } else {
      placeData.append(key, value);
    }
  }

  try {
    const { status } = await api.post("/place", placeData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    if (status === "success") {
      return true;
    }
  } catch (e) {
    console.log("placeCreate", e);
  }

  return false;
}

/**
 * 공간 임차 제안 신청 목록 API
 * @param {*} params
 * @returns
 */
export async function placeJoinOfferList(params) {
  try {
    const { status, data } = await api.get("/place_join_offer", { params });

    if (status === "success") {
      return data;
    }
  } catch (e) {
    console.log("placeJoinOfferList", e);
  }

  return { currentPage: 1, data: [], total: 0, lastPage: 1 };
}

/**
 * 공간 임차 제안 신청 생성 API
 * @returns
 */
export async function placeJoinOfferCreate(payload) {
  try {
    const { status } = await api.post("/place_join_offer", payload);

    if (status === "success") {
      return true;
    }
  } catch (e) {
    console.log("placeJoinOfferCreate", e);
  }

  return false;
}

export async function placeJoinOfferUpdate(id, payload) {
  try {
    const { status } = await api.post(`/place_join_offer/${id}`, payload);

    if (status === "success") {
      return true;
    }
  } catch (e) {
    console.log("placeJoinOfferUpdate", e);
  }

  return false;
}

export async function placeJoinOfferDetail(id) {
  try {
    const { status, data } = await api.get(`/place_join_offer/${id}`);

    if (status === "success") {
      return data;
    }
  } catch (e) {
    console.log("placeJoinOfferDetail", e);
  }

  return {};
}

export async function placeFormList() {
  try {
    const { status, data } = await api.get("/place_form");

    if (status === "success") {
      return data;
    }
  } catch (e) {
    console.log("placeFormList", e);
  }

  return [];
}

export async function placeClassificationList() {
  try {
    const { status, data } = await api.get("/place_classification");

    if (status === "success") {
      return data;
    }
  } catch (e) {
    console.log("placeClassificationList", e);
  }

  return [];
}

export async function placeLeaseList() {
  try {
    const { status, data } = await api.get("/place_lease_type");

    if (status === "success") {
      return data;
    }
  } catch (e) {
    console.log("placeLeaseList", e);
  }

  return [];
}

export async function placeAddSubscribe(userId, placeId) {
  try {
    const { status } = await api.post("/user_subscribe_place", {
      userId,
      placeId,
    });

    if (status === "success") {
      return true;
    }
  } catch (e) {
    console.log("placeSubscribe", e);
  }

  return false;
}

export async function placeRemoveSubscribe(placeId) {
  try {
    await api.delete(`/user_subscribe_place/my/${placeId}`);
    return true;
  } catch (e) {
    console.log("placeSubscribe", e);
  }

  return false;
}

export async function placeRemove(placeId) {
  try {
    await api.delete(`/place/${placeId}`);
    return true;
  } catch (e) {
    console.log("placeRemove", e);
  }

  return false;
}

export async function placeJoinOfferRemove(offerId) {
  try {
    await api.delete(`/place_join_offer/${offerId}`);
    return true;
  } catch (e) {
    console.log("placeJoinOfferRemove", e);
  }
  return false;
}
