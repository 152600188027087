import api from "./api";

export async function noticeList(page) {
  try {
    const { status, data } = await api.get("notice", {
      params: { paginate: 5, page },
    });

    if (status === "success") {
      return { data, success: true };
    }
  } catch (e) {
    console.log(e);
  }

  return { success: false, data: [] };
}

export async function noticeDetail(id) {
  try {
    const { status, data } = await api.get(`notice/${id}`);

    if (status === "success") {
      return { data, success: true };
    }
  } catch (e) {
    console.log(e);
  }

  return { success: false, data: {} };
}

export async function qnaList(page) {
  try {
    const { status, data } = await api.get("qna", {
      params: { paginate: 13, page },
    });

    if (status === "success") {
      return data;
    }
  } catch (e) {
    console.log(e);
  }

  return [];
}

export async function qnaDetail(id) {
  try {
    const { status, data } = await api.get(`qna/${id}`);

    if (status === "success") {
      return data;
    }
  } catch (e) {
    console.log("qnaDetail: ", e);
  }

  return {};
}

export async function qnaCategory() {
  try {
    const { status, data } = await api.get("qna_category");

    if (status === "success") {
      return data;
    }
  } catch (e) {
    console.log("qnaCategory: ", e);
  }

  return {};
}

export async function qnaCreate(payload) {
  const qnaData = new FormData();
  for (const [key, value] of Object.entries(payload)) {
    if (Array.isArray(value)) {
      value.forEach((v) => {
        qnaData.append(key, v);
      });
    } else {
      qnaData.append(key, value);
    }
  }

  try {
    const { status } = await api.post("/qna", qnaData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    if (status === "success") {
      return true;
    }
  } catch (e) {
    console.log("qnaCreate: ", e);
  }

  return false;
}

export async function qnaUpdate(id, payload) {
  const qnaData = new FormData();
  for (const [key, value] of Object.entries(payload)) {
    if (Array.isArray(value)) {
      value.forEach((v) => {
        qnaData.append(key, v);
      });
    } else {
      qnaData.append(key, value);
    }
  }

  try {
    const { status } = await api.post(`/qna/${id}`, qnaData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    if (status === "success") {
      return true;
    }
  } catch (e) {
    console.log("qnaUpdate: ", e);
  }

  return false;
}

export async function faqList(params) {
  try {
    const { status, data } = await api.get("/faq", { params });

    if (status === "success") {
      return data;
    }
  } catch (e) {
    console.log("faqList", e);
  }

  return { currentPage: 1, data: [], total: 0, lastPage: 1 };
}
