import axios from "axios";
import applyCaseMiddleware from "axios-case-converter";

const BASE_URL = (process.env.VUE_APP_IS_LOCAL_SERVER == 1 ? process.env.VUE_APP_API_BASEURL_LOCAL : process.env.VUE_APP_API_BASEURL_SERVER);

const instance = applyCaseMiddleware(
  axios.create({
    baseURL: `${BASE_URL}/api`,
    headers: {
      "Content-Type": "application/json",
    },
  })
);

// 로컬스토리지에 token 값이 있으면 불러와 API 호출 인증에 사용
instance.interceptors.request.use(
  function(config) {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function(response) {
    const { data } = response;
    return data;
  },
  function(error) {
    return Promise.reject(error);
  }
);

export default instance;
