import api from "./api";

export async function lessorCreate(payload) {
  const lessorData = new FormData();
  for (const [key, value] of Object.entries(payload)) {
    lessorData.append(key, value);
  }

  try {
    const { status, data } = await api.post("/lessor_office", lessorData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    if (status === "success") {
      console.log("C", data);
      return [];
    }
  } catch (e) {
    console.log("D", e);
  }

  return [];
}
