import api from "./api";

export async function userList(params) {
  try {
    const { status, data } = await api.get("/user", { params });

    if (status === "success") {
      return data;
    }
  } catch (e) {
    console.log(e);
  }

  return [];
}

export async function userDetail(userId) {
  try {
    const { status, data } = await api.get(`/user/${userId}`);

    if (status === "success") {
      return { user: data, success: true };
    }
  } catch (e) {
    console.log(e);
  }

  return { success: false, user: null };
}

export async function userUpdate(userId, payload) {
  const userData = new FormData();
  for (const [key, value] of Object.entries(payload)) {
    if (Array.isArray(value)) {
      value.forEach((v) => {
        userData.append(key, v);
      });
    } else {
      userData.append(key, value);
    }
  }

  try {
    const { status } = await api.post(`/user/${userId}`, userData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    if (status === "success") {
      return true;
    }
  } catch (e) {
    console.log("userUpdate: ", e);
  }

  return false;
}

export async function termCategory() {
  try {
    const { status, data } = await api.get("/terms_category");
    if (status === "success") {
      return data;
    }
  } catch (e) {
    console.log("termCategory: ", e);
  }
  return [];
}

export async function terms(params) {
  try {
    const { status, data } = await api.get("/terms", { params });
    if (status === "success") {
      return data;
    }
  } catch (e) {
    console.log("termCategory: ", e);
  }
  return [];
}

export async function getMarketing(params) {
  try {
    const { status, data } = await api.get("/marketing_video", { params });
    if (status === "success") {
      return data;
    }
  } catch (e) {
    console.log("termCategory: ", e);
  }
  return [];
}
