<template>
  <div class="w-full h-full">
    <!-- <div
      v-if="isMobile"
      class="h-full mobile-only flex flex-col items-center justify-center"
    >
      <h3>다시 태어나는 공간<br />앱으로 더 쉽고 빠르게!</h3>

      <img src="@/assets/mobile.png" />

      <button @click="goToMarket">앱 다운로드</button>
    </div> -->
    <div class="w-full h-full flex flex-col">
      <Header />
      <div class="w-full flex-1 flex flex-col">
        <router-view />
      </div>
      <Footer />
    </div>
  </div>
</template>


<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  computed: {
    ...mapState("user", ["isLoggedIn", "userType"]),
  },
  methods: {
    goToMarket() {
      window.open(
        "https://play.google.com/store/apps/details?id=com.flutter.brand_u",
        "_blank"
      );
    },
    ...mapActions("user", ["me"]),
    ...mapActions("place", ["preparePlace"]),
    ...mapActions("interior", ["prepareInterior"]),
  },
  created() {
    this.isMobile = this.$isMobile();
  },
  mounted() {
    this.preparePlace();
    this.prepareInterior();
    if (this.isLoggedIn) {
      this.me();
    }
  },
  watch: {
    userType(newValue) {
      let name = "";
      switch (newValue) {
        case "normal":
          name = "일반회원";
          break;
        case "lessor":
          name = "임대인";
          break;
        case "interior":
          name = "인테리어";
          break;
        default:
          break;
      }

      if (name) {
        this.$toast.open(`${name}회원으로 전환되었습니다`);
      }
    },
  },
};
</script>

<style scoped lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mobile-only {
  background-color: rgba(0, 0, 0, 0.9);

  h3 {
    font-size: 24px;
    text-align: center;
    color: white;
    margin-bottom: 20px;
  }

  img {
    width: 60%;
    margin-right: -6%;
  }

  button {
    background-color: #293bf0;
    width: 80%;
    height: 60px;
    border-radius: 40px;
    color: #fff;
  }
}
</style>
