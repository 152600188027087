import services from "@/services";

const LOADING = "LOADING";
const ADD_REVIEWS = "ADD_REVIEWS";

const RECENT = "RECENT";

export const review = {
  namespaced: true,
  state: () => ({
    reviews: [],
    isLoading: false,
    recents: localStorage.getItem("recentReview")
      ? JSON.parse(localStorage.getItem("recentReview"))
      : [],
  }),
  mutations: {
    [LOADING](state, payload) {
      state.isLoading = payload;
    },
    [ADD_REVIEWS](state, payload) {
      state.reviews = payload;
    },
    [RECENT](state, payload) {
      localStorage.setItem("recentReview", JSON.stringify(payload));
      state.recents = payload;
    },
  },
  actions: {
    setRecents({ commit }, payload) {
      commit(RECENT, payload);
    },
    async getReviewList({ commit }) {
      commit(LOADING, true);
      const { data } = await services.reviewList({
        paginate: 8,
        page: 1,
        isInteriorJoinOffer: 1,
      });
      commit(ADD_REVIEWS, data);
      commit(LOADING, false);
    },
  },
  getters: {},
};
