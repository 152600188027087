import api from "./api";

export async function reviewList(params) {
  try {
    const { status, data } = await api.get("/trade_review", { params });

    if (status === "success") {
      return data;
    }
  } catch (e) {
    console.log(e);
  }

  return { currentPage: 1, data: [], total: 0, lastPage: 1 };
}

export async function reviewCreate(payload) {
  const reviewData = new FormData();
  for (const [key, value] of Object.entries(payload)) {
    if (Array.isArray(value)) {
      value.forEach((v) => {
        reviewData.append(key, v);
      });
    } else {
      reviewData.append(key, value);
    }
  }

  try {
    const { status } = await api.post("/trade_review", reviewData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    if (status === "success") {
      return true;
    }
  } catch (e) {
    console.log("reviewCreate", e);
  }

  return false;
}

export async function reviewRemove(id) {
  try {
    await api.delete(`/trade_review/${id}`);
    return true;
  } catch (e) {
    console.log("reviewRemove", e);
  }

  return false;
}
