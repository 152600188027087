import services from "@/services";
import router from "@/router";

const LOGIN = "LOGIN";
const LOGOUT = "LOGOUT";
const ME = "ME";

const SIGNUP = "SIGNUP";
const LOADING = "LOADING";

const USER_TYPE = "USER_TYPE";

export const user = {
  namespaced: true,
  state: () => ({
    isLoggedIn: !!localStorage.getItem("token"),
    userId: localStorage.getItem("userId"),
    isLoading: false,
    user: null,
    userType: localStorage.getItem("userType")
      ? localStorage.getItem("userType")
      : "normal",
  }),
  mutations: {
    [LOADING](state, payload) {
      state.isLoading = payload;
    },
    [SIGNUP](state, payload) {
      state.user = payload;
    },
    [LOGIN](state, payload) {
      state.isLoggedIn = true;
      state.user = payload;
      state.userId = payload.id;
    },
    [LOGOUT](state) {
      state.isLoggedIn = false;
      state.user = null;
    },
    [ME](state, payload) {
      state.user = payload;
      state.userId = payload.id;
    },
    [USER_TYPE](state, payload) {
      localStorage.setItem("userType", payload);
      state.userType = payload;
    },
  },
  actions: {
    async signup({ commit }, payload) {
      commit(LOADING, true);
      const { user, success } = await services.authSignup(payload);
      commit(LOADING, false);

      if (success) {
        commit(SIGNUP, user);
      }
    },
    async login({ commit }, payload) {
      commit(LOADING, true);
      const {redirect, ...credentials} = payload;
      const { user, token, success } = await services.authLogin(credentials);
      commit(LOADING, false);

      if (success) {
        const userId = user.id;

        localStorage.setItem("token", token);
        localStorage.setItem("userId", userId);

        commit(LOGIN, user);
        commit(USER_TYPE, "normal");
        this._vm.$toast.open("반갑습니다!");
        router.push(redirect ? redirect : {name: "Home"});
      } else {
        this._vm.$toast.open({ message: "로그인 실패", type: "error" });
      }
    },
    async social({ commit }, payload) {
      commit(LOADING, true);
      const { site, ...data } = payload;
      let user,
        token,
        success = false;

      if (site === "kakao") {
        const d = await services.authSocialKakao(data);
        user = d.user;
        token = d.token;
        success = d.success;
      } else if (site === "naver") {
        const d = await services.authSocialNaver(data);
        user = d.user;
        token = d.token;
        success = d.success;
      } else {
        const d = await services.authSocial(payload);
        user = d.user;
        token = d.token;
        success = d.success;
      }
      commit(LOADING, false);

      if (success) {
        const userId = user.id;

        localStorage.setItem("token", token);
        localStorage.setItem("userId", userId);

        commit(LOGIN, user);
        commit(USER_TYPE, "normal");
        this._vm.$toast.open("반갑습니다!");
        router.push({ name: "Home" });
      } else {
        this._vm.$toast.open({ message: "로그인 실패", type: "error" });
      }
    },
    async logout({ commit }) {
      commit(LOADING, true);
      await services.authLogout();
      commit(LOADING, false);

      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      commit(LOGOUT);
      router.push({ name: "Login" });
    },
    async me({ commit }) {
      const userId = localStorage.getItem("userId");
      if (userId) {
        const { user, success } = await services.userDetail(userId);
        if (success) {
          commit(ME, user);
        }
      }
    },
    changeUserType({ commit }, payload) {
      commit(USER_TYPE, payload);
    },
  },
  getters: {
    getName: (state) => {
      return state.user?.name ?? "";
    },
    getUser: (state) => {
      const defaultUser = {
        name: "",
        nickname: "",
        userType: state.userType,
      };

      return Object.assign({}, defaultUser, state.user ?? {});
    },
    getInteriorOffice: (state) => {
      const { hasInteriorOffices } = state.user;
      if (hasInteriorOffices && hasInteriorOffices.length > 0) {
        return hasInteriorOffices[0];
      }
      return null;
    },
  },
};
